import Vue from 'vue'
import {EagleListConfigInterface, TableDataType} from '@/components/list/types/list'
class listConfig {
  protected vm?: Vue
  protected listKey?: string

  get(vm: Vue, listKey: string) : EagleListConfigInterface {
    this.vm = vm
    this.listKey = listKey

    return {
      pageTitle: () => {
        const page = this.vm?.$t('module.page')
        // @ts-ignore
        const pageType = this.vm?.$t(`page.type.${this.vm?.pageType}`)
        return `${page}-${pageType}`
      },
      hasWritePermission: (hasRole) : boolean => hasRole(['ROLE_PAGE']),
      search: 'page.search_keyword',
      filter: {
        status: {
          label: 'data.status',
          type: 'selection',
          options: [false, true],
          optionTextPrefix: 'data.status',
        },
        locked: {
          label: 'page.data.locked',
          type: 'selection',
          options: [false, true],
          optionTextPrefix: 'data',
        },
        sitemap: {
          label: 'page.data.sitemap',
          type: 'selection',
          options: [false, true],
          optionTextPrefix: 'data',
        },
        categories: {
          label: 'data.category',
          type: 'selection',
          optionTextKey: 'name',
          optionValueKey: 'id',
          multiple: true,
          optionMeta: item => ({ depth: item.depth }),
          optionText: (itemName: string, item: any) => this.vm?.$helper.nestedSetNodeNamePrefix(item.name, item.depth),
          options: (listMeta) => {
            if(!listMeta) return []
            if(!Array.isArray(listMeta.categories)) return []
            return listMeta.categories
          },
        },
        created_at: {
          label: 'data.created_at',
          type: 'date',
        },
      },
      exportExcel: {
        filename: (time: string) => `${this.vm?.$t('module.page')}`,
        withoutConfirm: true,
        sheets: [
          {
            label: 'module.page',
            data: {
              id: { label: 'ID', width: 20 },
              title: { label: 'data.title', width: 22 },
              code: { label: 'data.code' },
              keywords: { label: 'data.keywords' },
              photo: {
                label: 'data.photo',
                value: row => this.vm?.$helper.getPhotoUrl(row.photo, ['origin'])
              },
              brief: { label: 'data.brief' },
              status: {
                label: 'data.status',
                value: row => this.vm?.$t(`data.status.${!!row.status}`),
              },
              locked: {
                label: 'page.data.locked',
                value: row => this.vm?.$t(`data.${!!row.status}`),
              },
              publish_start: { label: 'page.data.publish_start', width: 18 },
              publish_end: { label: 'page.data.publish_end', width: 18 },
              created_at: { label: 'data.created_at', width: 18 },
            },
          },
        ]
      },
      selectedData: row => ({
        id: row.id,
        title: row.title,
      }),
      displayMode: 'table',
      displayModeTypes: ['card', 'table'],
      sort: [
        { label: 'data.created_at', key: 'created_at' },
      ],
      card: {
        title: row => row.title,
        subtitle: row => row.brief,
        avatarPhoto: row => row.photo,
      },
      table: {
        data: <TableDataType>[
          {
            key: 'photo',
            label: 'data.photo',
            type: 'photo',
            width: 80,
            maxWidth: 80,
          },
          {
            key: 'title',
            label: 'data.title',
            type: 'text',
            truncate: true,
            copyable: true,
            width: 150,
            maxWidth: 150,
          },
          {
            key: 'categories',
            label: 'data.category',
            type: 'array',
            chip: true,
            chipProperty: (row, item, itemIndex) => ({ color: 'primary' }),
            itemText: item => item.name,
          },
          {
            key: 'code',
            copyable: true,
            label: 'page.data.code',
            type: 'text',
            width: 150,
            maxWidth: 150,
          },
          {
            key: 'status',
            label: 'data.status',
            type: 'switch',
            width: 50,
          },
          {
            key: 'locked',
            label: 'page.data.locked',
            type: 'switch',
            width: 50,
          },
          {
            key: 'sitemap',
            label: 'page.data.sitemap',
            type: 'switch',
            width: 120,
          },
          {
            key: 'created_at',
            label: 'data.created_at',
            type: 'time',
            width: 160,
            maxWidth: 160,
          },
        ],
      },
      batch: {
        delete: {
          targetLabel: row => row.title,
        },
      },
      create: {
        popupComponent: () => import('@/modules/page/views/pageList/pageListCreatePopup.vue'),
        disabledApply: (formData) => {
          if(window.helper.textEmpty(formData.title)) return true
          if(window.helper.textEmpty(formData.code)) return true
          return false
        },
        createApi: async (formData) => {
          // @ts-ignore
          formData.type = this.vm?.pageType
          return await this.vm?.$api.collection.pageApi.create(formData)
        },
        redirect: (result => ({
          name: `page-update-${result.type}`,
          params: { target: result.id },
        }))
      },
      dataActionFlexColumnInTableMode: true,
      dataAction: {
        update: {
          label: 'action.edit',
          create: (row: AnyObject, hasWritePermission: boolean) => hasWritePermission,
          route: row => ({
            name: `page-update-${row.type}`,
            params: { target: row.id },
          }),
          linkTarget: '_self',
        },
        preview: {
          label: 'action.preview',
          color: 'green',
          buttonProperties: () => ({ class: 'white--text' }),
          href: row => {
            if(row.status == true) {
              // @ts-ignore
              return `${this.vm.frontendDomain}/page/${row.type}/${row.code}`
            }
            // @ts-ignore
            return `${this.vm.frontendDomain}/secret-page/${row.id}?p=${row.p}`
          },
          linkTarget: '_blank',
        },
        delete: {
          create: (row: AnyObject, hasWritePermission: boolean) => hasWritePermission,
          removeTarget: row => row.name,
        },
      },
    }

  }
}

export default new listConfig()
